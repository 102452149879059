import React, { useEffect, useState } from "react";
import {Card, Select, Row, Col, Tooltip} from "antd";
import { useNavigate } from "react-router-dom";
import api from "../api/api";
import moment from "moment";

const { Option } = Select;

const OrdersPage = () => {
    const [orders, setOrders] = useState([]);
    const defaultDateRange = [
        moment().startOf("day").format("YYYY-MM-DD"),
        moment().endOf("day").format("YYYY-MM-DD")
    ];
    const [deliveryMethod, setDeliveryMethod] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [dateRange, setDateRange] = useState(defaultDateRange);

    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState("ASC");
    const navigate = useNavigate();

    const selectedCity = localStorage.getItem("adminCity") || "all";

    const fetchOrders = async () => {
        try {
            const params = new URLSearchParams();

            if (selectedCity !== "all") params.append("cityId", selectedCity);
            if (deliveryMethod) params.append("deliveryMethod", deliveryMethod);
            if (paymentMethod) params.append("paymentMethod", paymentMethod);
            if (sortBy) params.append("sortBy", sortBy);
            if (sortOrder) params.append("sortOrder", sortOrder);

            if (dateRange && dateRange.length === 2) {
                params.append("startDate", dateRange[0]);
                params.append("endDate", dateRange[1]);
            }

            const url = `/orders${params.toString() ? `?${params.toString()}` : ""}`;
            const { data } = await api.get(url);
            setOrders(data);
        } catch (error) {
            console.error("Ошибка загрузки заказов:", error);
            setOrders([]); // Если ошибка, отображаем пустой массив
        }
    };

    const handleDateChange = (value) => {
        const today = moment().startOf("day").format("YYYY-MM-DD");
        const ranges = {
            today: [today, today],
            yesterday: [
                moment().subtract(1, "days").startOf("day").format("YYYY-MM-DD"),
                moment().subtract(1, "days").endOf("day").format("YYYY-MM-DD"),
            ],
            week: [
                moment().subtract(7, "days").startOf("day").format("YYYY-MM-DD"),
                today,
            ],
            month: [
                moment().subtract(1, "months").startOf("day").format("YYYY-MM-DD"),
                today,
            ],
            all: [],
        };
        setDateRange(ranges[value]);
    };

    const viewOrderDetails = (orderId) => {
        navigate(`/admin/orders/${orderId}`);
    };

    useEffect(() => {
        fetchOrders();
    }, [selectedCity, deliveryMethod, paymentMethod, dateRange, sortBy, sortOrder]);

    return (
        <div className="orders-page-container">
            {/* Фильтры */}
            <Row gutter={[16, 16]} className="filter-section">
                <Col xs={24} sm={12} md={8}>
                    <Select
                        placeholder="Способ доставки"
                        value={deliveryMethod}
                        onChange={setDeliveryMethod}
                        style={{ width: "100%" }}
                        allowClear
                    >
                        <Option value="delivery">Доставка</Option>
                        <Option value="pickup">Самовывоз</Option>
                    </Select>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Select
                        placeholder="Способ оплаты"
                        value={paymentMethod}
                        onChange={setPaymentMethod}
                        style={{ width: "100%" }}
                        allowClear
                    >
                        <Option value="card">Карта</Option>
                        <Option value="cash">Наличные</Option>
                    </Select>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Select
                        placeholder="Выбрать период"
                        onChange={(value) => handleDateChange(value)}
                        style={{ width: "100%" }}
                        allowClear
                    >
                        <Option value="today">Сегодня</Option>
                        <Option value="yesterday">Вчера</Option>
                        <Option value="week">Неделя</Option>
                        <Option value="month">Месяц</Option>
                        <Option value="all">Все заказы</Option>
                    </Select>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Select
                        placeholder="Сортировать по"
                        onChange={(value) => setSortBy(value)}
                        style={{ width: "100%" }}
                        allowClear
                    >
                        <Option value="createdAt">Дата создания</Option>
                        <Option value="totalAmount">Сумма</Option>
                    </Select>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Select
                        placeholder="Порядок сортировки"
                        value={sortOrder}
                        onChange={setSortOrder}
                        style={{ width: "100%" }}
                    >
                        <Option value="ASC">По возрастанию</Option>
                        <Option value="DESC">По убыванию</Option>
                    </Select>
                </Col>
            </Row>

            {/* Заглушка или список заказов */}
            {orders.length === 0 ? (
                <div style={{ marginTop: "20px", textAlign: "center", fontSize: "16px" }}>
                    За указанный период нет заказов, соответствующих выбранным фильтрам.
                </div>
            ) : (
                <Row gutter={[16, 16]}>
                    {orders.map((order) => (
                        <Col xs={24} sm={12} md={8} lg={6} key={order.id}>
                            <Card
                                hoverable
                                className="order-card"
                                onClick={() => viewOrderDetails(order.id)}
                                style={{
                                    borderRadius: "8px",
                                    overflow: "hidden",
                                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                    transition: "transform 0.3s, box-shadow 0.3s",
                                }}
                                bodyStyle={{
                                    padding: "16px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                }}
                                onMouseEnter={(e) =>
                                    e.currentTarget.style.transform = "scale(1.05)"
                                }
                                onMouseLeave={(e) =>
                                    e.currentTarget.style.transform = "scale(1)"
                                }
                            >
                                <div style={{ marginBottom: "16px" }}>
                                    <h3 style={{
                                        fontSize: "16px",
                                        marginBottom: "8px",
                                        color: "#1890ff",
                                    }}>
                                        {order.customerName || "Имя клиента не указано"}
                                    </h3>
                                    <Tooltip title={order.customerPhone}>
                                        <p style={{
                                            fontSize: "14px",
                                            margin: 0,
                                            color: "#555",
                                        }}>
                                            <strong>Телефон:</strong> {order.customerPhone}
                                        </p>
                                    </Tooltip>
                                    <p style={{
                                        fontSize: "14px",
                                        margin: 0,
                                        color: "#555",
                                    }}>
                                        <strong>Город:</strong> {order.city?.name || "Не указан"}
                                    </p>
                                </div>
                                <div>
                                    <p style={{
                                        fontSize: "14px",
                                        margin: 0,
                                        fontWeight: "bold",
                                    }}>
                                        Сумма: <span style={{
                                        color: "#52c41a",
                                    }}>
                                    {order.totalAmount} ₸
                                </span>
                                    </p>
                                    <p style={{
                                        fontSize: "12px",
                                        margin: "4px 0 0",
                                        color: "#888",
                                    }}>
                                        Дата: {moment(order.createdAt).format("DD.MM.YYYY")}
                                    </p>
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            )}
        </div>
    );
};

export default OrdersPage;

