import React from "react";
import ProductForm from "./ProductForm";

const ProductCreatePage = () => {
    return (
        <div>
            <h1>Добавить товар</h1>
            <ProductForm />
        </div>
    );
};

export default ProductCreatePage;