import React, { useEffect, useState } from "react";
import { Table, Button, Space, Typography, Input, Select, Spin } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import api from "../api/api";
import { useMediaQuery } from "react-responsive";

const { Title } = Typography;
const { Search } = Input;
const { Option } = Select;

const ProductListPage = () => {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [pagination, setPagination] = useState({ current: 1, pageSize: 20 });
    const [loading, setLoading] = useState(false); // Лоадер

    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery({ query: "(max-width: 768px)" });
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const categoryFromURL = searchParams.get("category") || "";
        const searchFromURL = searchParams.get("search") || "";
        const pageFromURL = parseInt(searchParams.get("page")) || 1;
        const pageSizeFromURL = parseInt(searchParams.get("pageSize")) || 50;

        setSelectedCategory(categoryFromURL);
        setSearchValue(searchFromURL);
        setPagination({ current: pageFromURL, pageSize: pageSizeFromURL });
    }, [searchParams]);

    const fetchProducts = async () => {
        setLoading(true); // Показываем лоадер перед запросом
        try {
            const { data } = await api.get("/products");
            const transformedProducts = data.map((product) => ({
                ...product,
                categoryName: product.category?.name,
                subcategoryName: product.subcategory?.name,
                defaultPrice: product.prices[0]?.price || 0,
                defaultDiscount: product.prices[0]?.discount || 0,
                imageUrl: `/api${product.image}`,
            }));
            setProducts(transformedProducts);
            setFilteredProducts(filterProducts(transformedProducts, selectedCategory, searchValue));

            const uniqueCategories = [...new Set(transformedProducts.map((product) => product.categoryName))].filter(Boolean);
            setCategories(uniqueCategories);
        } catch (error) {
            console.error("Ошибка загрузки товаров:", error);
        } finally {
            setLoading(false); // Скрываем лоадер после завершения запроса
        }
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    const filterProducts = (products, category, search) => {
        const lowercasedSearch = search.toLowerCase();
        return products.filter((product) =>
            (category ? product.categoryName === category : true) &&
            (product.name.toLowerCase().includes(lowercasedSearch) ||
                product.subcategoryName?.toLowerCase().includes(lowercasedSearch))
        );
    };

    useEffect(() => {
        setFilteredProducts(filterProducts(products, selectedCategory, searchValue));
    }, [selectedCategory, searchValue, products]);

    const handleSearch = (value) => {
        setSearchValue(value);
        updateURL({ search: value, page: 1 });
    };

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        updateURL({ category, page: 1 });
    };

    const updateURL = (updates) => {
        setSearchParams((prev) => {
            const newParams = new URLSearchParams(prev);
            Object.entries(updates).forEach(([key, value]) => {
                if (value) {
                    newParams.set(key, value);
                } else {
                    newParams.delete(key);
                }
            });
            return newParams;
        });
    };

    const handleTableChange = (pagination) => {
        setPagination(pagination);
        updateURL({ page: pagination.current, pageSize: pagination.pageSize });
    };

    const columns = [
        {
            title: "Название",
            dataIndex: "name",
            key: "name",
            render: (text) => <strong>{text}</strong>,
        },
        {
            title: "Категория",
            dataIndex: "categoryName",
            key: "categoryName",
        },
        {
            title: "Подкатегория",
            dataIndex: "subcategoryName",
            key: "subcategoryName",
        },
        {
            title: "Цена",
            dataIndex: "defaultPrice",
            key: "defaultPrice",
            render: (price) => `${price.toLocaleString()} ₸`,
        },
        {
            title: "Действия",
            key: "actions",
            render: (_, record) => (
                <Space direction={isSmallScreen ? "vertical" : "horizontal"}>
                    <Button type="primary" onClick={() => navigate(`/admin/products/view/${record.id}`)}>Подробно</Button>
                    <Button type="default" onClick={() => navigate(`/admin/products/edit/${record.id}`)}>Изменить</Button>
                </Space>
            ),
        },
    ];

    return (
        <div style={{ padding: "20px" }}>
            <Title level={3} style={{ marginBottom: 16 }}>Список товаров</Title>
            <Space style={{ marginBottom: 16, width: "100%" }} direction="vertical">
                <Space wrap>
                    <Search placeholder="Поиск по названию или подкатегории" allowClear value={searchValue} onChange={(e) => handleSearch(e.target.value)} style={{ maxWidth: 400 }} />
                    <Select placeholder="Выберите категорию" allowClear value={selectedCategory} onChange={handleCategoryChange} style={{ minWidth: 200 }}>
                        {categories.map((category) => (
                            <Option key={category} value={category}>{category}</Option>
                        ))}
                    </Select>
                </Space>
                <Button type="primary" onClick={() => navigate("/admin/products/create")}>Добавить товар</Button>
            </Space>
            {loading ? (
                <div style={{ textAlign: "center", marginTop: 50 }}>
                    <Spin size="large" />
                </div>
            ) : (
                <Table
                    dataSource={filteredProducts}
                    columns={columns}
                    rowKey="id"
                    bordered
                    pagination={{
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        showSizeChanger: true,
                        pageSizeOptions: ["20", "50", "100", "200"],
                    }}
                    onChange={handleTableChange}
                    style={{
                        background: "#fff",
                        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                        borderRadius: "8px",
                    }}
                />
            )}
        </div>
    );
};

export default ProductListPage;
